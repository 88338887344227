import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Skeleton } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import axiosInstance from "../../../axiosInterceptor";
import { format } from "date-fns";

const CustomAlert = ({ visible, closeModal, idHabitualidade }) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const chave = window.localStorage.getItem("chaveCofre");
  const [loading, setLoading] = useState(false);
  const [habitualidade, setHabitualidade] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [b64Img, setb64Img] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };
        const data2 = {
          chave: chave,
        };
        const response = await axiosInstance.post(
          `https://app-photo-api-java.herokuapp.com/v1/habitualidade/${idHabitualidade}/buscarnova`,
          data2,
          options
        );
        setHabitualidade(response.data);
        setb64Img(response.data.imagem);
      } catch (error) {
        setLoading(false);
        console.error("Erro ao carregar os dados:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [accessToken, idHabitualidade]);

  function formatarCPF(cpf) {
    const cpfFormatado = cpf.replace(/\D/g, "");

    if (cpfFormatado.length !== 11) {
      return "CPF inválido";
    }

    // Aplica a formatação ao CPF
    return cpfFormatado.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      "$1.$2.$3-$4"
    );
  }
  const [modalVisible2, setModalVisible2] = useState(false);

  const openModal2 = () => {
    setModalVisible2(true);
  };

  const closeModal2 = () => {
    setModalVisible2(false);
  };

  return (
    <Modal
      title="Detalhes da habitualidade"
      visible={visible}
      onCancel={closeModal}
      footer={null}
    >
      <div>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <CircularImageDiv>
                  {chave ? (
                    <CircularImage
                      src={b64Img ? "data:image/png;base64," + b64Img : ""}
                      onClick={openModal2}
                    />
                  ) : (
                    <CircularImage />
                  )}
                </CircularImageDiv>
                <Modal
                  title="Imagem Ampliada"
                  visible={modalVisible2}
                  onCancel={closeModal2}
                  footer={null}
                >
                  {b64Img && (
                    <ModalImage src={`data:image/png;base64,${b64Img}`} />
                  )}
                </Modal>
              </Col>
              <Col span={12}>
                <span>
                  <strong>
                    {habitualidade
                      ? format(
                          new Date(habitualidade.data),
                          "dd/MM/yyyy HH:mm:ss"
                        )
                      : ""}
                  </strong>
                  <br />
                </span>
                <span>
                  <strong>Nome: </strong>
                  {habitualidade ? habitualidade.nome : ""}
                  <br />
                </span>

                <span>
                  <strong>Clube: </strong>
                  {habitualidade ? habitualidade.clube.nome : ""}
                  <br />
                </span>
                <span>
                  <strong>Calibre: </strong>
                  {habitualidade ? habitualidade.calibre : ""}
                  <br />
                </span>
                <span>
                  <strong>Disparos: </strong>
                  {habitualidade ? habitualidade.qtd_disparos : ""}
                  <br />
                </span>
                <span>
                  <strong>Nº de série: </strong>
                  {habitualidade ? habitualidade.num_serie_equipamento : ""}
                  <br />
                </span>

                <span>
                  <strong>Tipo: </strong>
                  {habitualidade ? habitualidade.tipoDaHabitualidade : ""}
                  <br />
                </span>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <br />

              <CustomCol>
                <h3>Observações</h3>
                <strong>{habitualidade ? habitualidade.obs : ""}</strong>
              </CustomCol>
            </Row>
          </>
        )}
      </div>
    </Modal>
  );
};
const CircularImageDiv = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
`;

const CircularImage = styled.img`
  width: 100%;
  height: 100%;
`;

const CustomCol = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid black; /* Adicione a borda aqui */
  flex-direction: column;
`;
const ModalImage = styled.img`
  width: 100%;
`;
export default CustomAlert;
