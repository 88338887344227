import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Card, Form, Input, Modal, Skeleton, Pagination } from "antd";
import EditarClube from "./EditarClube";
import { Link } from "react-router-dom";
import camuflado from "../../../camuflado.jpg";
import axiosInstance from "../../../axiosInterceptor";

const TableContainer = styled.div`
  max-width: 600px;
  margin: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

const ActionButton = styled.button`
  padding: 6px 10px;
  margin-left: 8px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
`;

const CrudClube = ({ recarregarDados }) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const [registros, setRegistros] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };
        const response = await axiosInstance.get(
          "https://app-photo-api-java.herokuapp.com/v1/clube/usuario/" + idUser,
          options
        );

        setRegistros(response.data);
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [idUser, accessToken, refreshing, recarregarDados]);

  const handleExcluir = (id) => {
    confirmarExclusao(id);
  };

  const confirmarExclusao = async (id) => {
    const confirmed = window.confirm(
      "Tem certeza de que deseja excluir o clube?"
    );

    if (confirmed) {
      try {
        // Faz a chamada para o endpoint DELETE
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };
        const response = await axiosInstance.delete(
          `https://app-photo-api-java.herokuapp.com/v1/clube/${id}`,
          options
        );
        if (response.status === 204) {
          window.alert("Registro excluído com sucesso!");
          setRefreshing(!refreshing);
        } else {
          window.alert("Erro", "Ocorreu um erro ao excluir o registro.");
        }
      } catch (error) {
        window.alert("Erro", "Ocorreu um erro ao excluir o registro.");
        console.error("Erro ao excluir o registro:", error);
      }
    }
  };

  const handleVerDetalhes = (item) => {
    window.alert(
      `Detalhes do clube\nNome: ${item.nome}\nCNPJ: ${item.cnpj}\nEndereço: ${item.endereco}`
    );
  };
  const handleEditar = (id) => {};

  const columns = [
    {
      title: "Nome do Clube",
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: "Ações",
      key: "acoes",
      render: (_, clube) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActionButton
            style={{ background: "#FF5733" }}
            onClick={() => handleExcluir(clube.id)}
          >
            <FontAwesomeIcon icon={faTrash} color="#fff" />
          </ActionButton>
          <Link to={`/editar-clube/${clube.id}`}>
            <ActionButton style={{ background: "#33A2FF" }}>
              <FontAwesomeIcon icon={faPencilAlt} color="#fff" />
            </ActionButton>
          </Link>
          <ActionButton
            style={{ background: "#33FF33" }}
            onClick={() => handleVerDetalhes(clube)}
          >
            <FontAwesomeIcon icon={faEye} color="#fff" />
          </ActionButton>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Card style={{ width: "85%", marginTop: "140px", marginBottom: "140px" }}>
        {loading ? (
          <Skeleton active />
        ) : (
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Nome do Clube</TableHeader>
                  <TableHeader>Ações</TableHeader>
                </tr>
              </thead>
              <tbody>
                {registros.map((clube) => (
                  <TableRow key={clube.id}>
                    <TableCell>{clube.nome}</TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ActionButton
                          style={{ background: "#FF5733" }}
                          onClick={() => handleExcluir(clube.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} color="#fff" />
                        </ActionButton>
                        <Link to={`/editar-clube/${clube.id}`}>
                          <ActionButton style={{ background: "#33A2FF" }}>
                            <FontAwesomeIcon icon={faPencilAlt} color="#fff" />
                          </ActionButton>
                        </Link>

                        <ActionButton style={{ background: "#33FF33" }}>
                          <FontAwesomeIcon
                            icon={faEye}
                            color="#fff"
                            onClick={() => handleVerDetalhes(clube)}
                          />
                        </ActionButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        )}
      </Card>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: "50";
  padding: 0;
`;

export default CrudClube;
