import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("idUser");
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
