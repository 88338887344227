import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";

import { Skeleton } from "antd";
import "./graficos.css";
import axiosInstance from "../../axiosInterceptor";

const GraficoComp = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [loading, setLoading] = useState(false);
  const [registrosGrafico, setRegistrosGrafico] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };

        const response = await axiosInstance.get(
          "https://app-photo-api-java.herokuapp.com/v1/habitualidade/anualComp/" +
            idUser,
          options
        );

        var data = [
          { name: "Janeiro", habitualidades: 0 },
          { name: "Fevereiro", habitualidades: 0 },
          { name: "Março", habitualidades: 0 },
          { name: "Abril", habitualidades: 0 },
          { name: "Maio", habitualidades: 0 },
          { name: "Junho", habitualidades: 0 },
          { name: "Julho", habitualidades: 0 },
          { name: "Agosto", habitualidades: 0 },
          { name: "Setembro", habitualidades: 0 },
          { name: "Outubro", habitualidades: 0 },
          { name: "Novembro", habitualidades: 0 },
          { name: "Dezembro", habitualidades: 0 },
        ];

        var novoRegistroGrafico = new Array();

        for (var i = 0; i < data.length; i++) {
          var novoRegistro = {
            name: data[i].name,
            habitualidades: response.data[i],
          };

          novoRegistroGrafico.push(novoRegistro);
        }
        setRegistrosGrafico(novoRegistroGrafico);
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [idUser, accessToken]);

  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;

    return (
      <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>
        {value}
      </text>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%", margin: "auto" }}>
        <h2 style={{ textAlign: "center" }}>
          Competições - Geral {new Date().getUTCFullYear()}
        </h2>
      </div>
      <div className="chart-container">
        {loading ? (
          <Skeleton active />
        ) : (
          <ResponsiveContainer width="100%" height={300} maxWidth="120%">
            <BarChart data={registrosGrafico}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                interval={0}
                tickFormatter={(value) =>
                  window.innerWidth < 768 ? value.substring(0, 3) : value
                }
              />
              <YAxis domain={[0, "dataMax + 1"]} />
              <Tooltip />
              <Legend />
              <Bar dataKey="habitualidades" fill="rgba(75,192,192,0.6)">
                <LabelList
                  dataKey="habitualidades"
                  content={renderCustomizedLabel}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default GraficoComp;
