import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Card,
  Row,
  Col,
  notification,
  Skeleton,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import CustomAlert from "./CustomAlert";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import "../styles.css";
import axiosInstance from "../../../axiosInterceptor";

const CrudHabitualidade = ({ onTabChange, recarregarDados }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [idHabitualidade, setIdHabitualidade] = useState({});
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [loading, setLoading] = useState(false);
  const [registros, setRegistros] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };

        const response = await axiosInstance.get(
          // 'https://app-photo-api-java.herokuapp.com/v1/habitualidade/usuario/' +
          "https://app-photo-api-java.herokuapp.com/v1/habitualidade/usuarioResumo/" +
            idUser,
          options
        );

        setRegistros(response.data);
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [idUser, accessToken, recarregarDados, reload]);

  const handleView = (id) => {
    setIdHabitualidade(id);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const columns = [
    {
      title: "Data",
      dataIndex: "data",
      key: "data",

      render: (date) => format(new Date(date), "dd/MM/yyyy HH:mm"),
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActionButton
            style={{ background: "#FF5733" }}
            onClick={() => handleDelete(record.id)}
          >
            <FontAwesomeIcon icon={faTrash} color="#fff" />
          </ActionButton>
          <Link to={`/editar-habitualidade/${record.id}`}>
            <ActionButton style={{ background: "#33A2FF" }}>
              <FontAwesomeIcon icon={faEdit} color="#fff" />
            </ActionButton>
          </Link>

          <ActionButton
            style={{ background: "#33FF33" }}
            onClick={() => handleView(record.id)}
          >
            <FontAwesomeIcon icon={faEye} color="#fff" />
          </ActionButton>
        </div>
      ),
    },
  ];

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Tem certeza que deseja excluir esta habitualidade?"
    );
    if (confirmed) {
      try {
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };

        const response = await axiosInstance.delete(
          `https://app-photo-api-java.herokuapp.com/v1/habitualidade/${id}`,
          options
        );
        setReload(!reload);

        if (response.status === 204) {
          notification.success({
            message: "Sucesso",
            description: "Registro excluído com sucesso!",
          });
        } else {
          notification.error({
            message: "Erro",
            description: "Ocorreu um erro ao excluir o registro",
          });
        }
      } catch (error) {
        notification.error({
          message: "Erro",
          description: "Ocorreu um erro ao excluir o registro",
        });
        console.error("Erro ao excluir o registro:", error);
      }
    } else {
    }
  };

  const handleEdit = (id) => {};

  return (
    <Container>
      <Card style={{ width: "85%" }}>
        <div>
          {loading ? (
            <Skeleton active />
          ) : (
            <Table
              dataSource={registros}
              columns={columns}
              pagination={{ pageSize: 5 }}
            />
          )}
          <CustomAlert
            visible={modalVisible}
            closeModal={closeModal}
            idHabitualidade={idHabitualidade}
          />
        </div>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 82vh;
  margin: 0;
  padding: 0;
`;
const ActionButton = styled.button`
  padding: 6px 10px;
  margin-left: 8px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
`;
export default CrudHabitualidade;
