import React, { useState, useEffect, useCallback } from "react";
import camuflado from "../../../camuflado.jpg";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import { Col, Card, Row, Typography } from "antd";
import { format } from "date-fns";
import GraficoAnual from "../../graficos/GraficoAnual";
import GraficoTreino from "../../graficos/GraficoTreino";
import GraficoComp from "../../graficos/GraficoComp";
import axiosInstance from "../../../axiosInterceptor";

const { Text } = Typography;

const Home = ({ recarregarDados }) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [loading, setLoading] = useState(false);
  const [qtdHabitualidade, setQtdHabitualidade] = useState(0);
  const [qtdHabitualidadeTreinamento, setQtdHabitualidadeTreinamento] =
    useState(0);
  const [qtdHabitualidadeCompeticao, setQtdHabitualidadeCompeticao] =
    useState(0);
  const [habitualidadeDados, setHabitualidadeDados] = useState(0);
  const [habitualidadeDadosComp, setHabitualidadeDadosComp] = useState(0);
  const [habitualidadeDadosTreino, setHabitualidadeDadosTreino] = useState(0);

  useEffect(() => {
    if (idUser) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const options = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          };

          const response = await axiosInstance.get(
            "https://app-photo-api-java.herokuapp.com/v1/habitualidade/contarHabitualidades/" +
              idUser,
            options
          );
          setQtdHabitualidade(response.data);
        } catch (error) {
          console.error("Erro ao carregar os dados:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [idUser, accessToken, recarregarDados]);

  useEffect(() => {
    if (idUser) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const options = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          };

          const response = await axiosInstance.get(
            "https://app-photo-api-java.herokuapp.com/v1/habitualidade/contaHabitualidadesTreino/" +
              idUser,
            options
          );

          setQtdHabitualidadeTreinamento(response.data);
        } catch (error) {
          console.error("Erro ao carregar os dados:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [idUser, accessToken, recarregarDados]);

  useEffect(() => {
    if (idUser) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const options = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          };

          const response = await axiosInstance.get(
            "https://app-photo-api-java.herokuapp.com/v1/habitualidade/contaHabitualidadesComp/" +
              idUser,
            options
          );

          setQtdHabitualidadeCompeticao(response.data);
        } catch (error) {
          console.error("Erro ao carregar os dados:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [idUser, accessToken, recarregarDados]);

  useEffect(() => {
    if (idUser) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const options = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          };

          const response = await axiosInstance.get(
            "https://app-photo-api-java.herokuapp.com/v1/habitualidade/ultimaHabitualidade/" +
              idUser,
            options
          );
          setHabitualidadeDados(response.data);
        } catch (error) {
          console.error("Erro ao carregar os dados:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [idUser, accessToken, recarregarDados]);

  useEffect(() => {
    if (idUser) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const options = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          };

          const response = await axiosInstance.get(
            "https://app-photo-api-java.herokuapp.com/v1/habitualidade/ultimaHabitualidadeComp/" +
              idUser,
            options
          );
          setHabitualidadeDadosComp(response.data);
        } catch (error) {
          console.error("Erro ao carregar os dados:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [idUser, accessToken, recarregarDados]);

  useEffect(() => {
    if (idUser) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const options = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          };

          const response = await axiosInstance.get(
            "https://app-photo-api-java.herokuapp.com/v1/habitualidade/ultimaHabitualidadeTreino/" +
              idUser,
            options
          );
          setHabitualidadeDadosTreino(response.data);
        } catch (error) {
          console.error("Erro ao carregar os dados:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [idUser, accessToken, recarregarDados]);

  return (
    <Container>
      <Row
        gutter={16}
        style={{
          justifyContent: "center",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <Card style={{ width: "80%", margin: "0 auto", padding: "20px" }}>
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "#fff",
                  border: "solid 1px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "24px",
                  fontWeight: "bold",
                  margin: "0 auto",
                }}
              >
                {qtdHabitualidade}
              </div>
            </Col>
            <Col xs={24} sm={16}>
              <div style={{ textAlign: "center" }}>
                <Text strong>Habitualidades nos últimos 12 meses.</Text>
              </div>
              <div style={{ textAlign: "center" }}>
                Considerando apenas uma por dia conforme regulamentação atual.
              </div>
            </Col>
          </Row>
        </Card>

        <Card style={{ width: "80%", marginTop: "20px" }}>
          <Row
            gutter={16}
            style={{
              height: "150px",
              alignItems: "center",
            }}
          >
            <Col span={24}>
              <Text strong>Último Registro</Text>
            </Col>
            <Col span={12}>
              Data:
              <br />
              Clube:
              <br />
              Calibre:
              <br />
              Disparos:
              <br />
              N.Série:
            </Col>
            <Col span={12}>
              {habitualidadeDados
                ? format(
                    new Date(habitualidadeDados.data),
                    "dd/MM/yyyy HH:mm:ss"
                  )
                : ""}
              <br />
              {habitualidadeDados.clube}
              <br />
              {habitualidadeDados.calibre}
              <br />
              {habitualidadeDados.qtd_disparos}
              <br />
              {habitualidadeDados.num_serie_equipamento}
            </Col>
          </Row>
        </Card>

        <Card
          style={{
            width: "80%",
            marginTop: "20px",
          }}
        >
          <Row
            gutter={16}
            style={{
              height: "350px",
              alignItems: "center",
            }}
          >
            <Col span={24}>
              <GraficoAnual></GraficoAnual>
            </Col>
          </Row>
        </Card>

        <Card
          style={{
            width: "80%",
            margin: "0 auto",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "#fff",
                  border: "solid 1px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "24px",
                  fontWeight: "bold",
                  margin: "0 auto",
                }}
              >
                {qtdHabitualidadeTreinamento}
              </div>
            </Col>
            <Col xs={24} sm={16}>
              <div style={{ textAlign: "center" }}>
                <Text strong>Treinamentos nos últimos 12 meses.</Text>
              </div>
              <div style={{ textAlign: "center" }}>
                Considerando apenas uma por dia conforme regulamentação atual.
              </div>
            </Col>
          </Row>
        </Card>

        <Card style={{ width: "80%", marginTop: "20px" }}>
          <Row
            gutter={16}
            style={{
              height: "150px",
              alignItems: "center",
            }}
          >
            <Col span={24}>
              <Text strong>Último Treino</Text>
            </Col>
            <Col span={12}>
              Data:
              <br />
              Clube:
              <br />
              Calibre:
              <br />
              Disparos:
              <br />
              N.Série:
            </Col>
            <Col span={12}>
              {habitualidadeDadosTreino
                ? format(
                    new Date(habitualidadeDadosTreino.data),
                    "dd/MM/yyyy HH:mm:ss"
                  )
                : ""}
              <br />
              {habitualidadeDadosTreino.clube}
              <br />
              {habitualidadeDadosTreino.calibre}
              <br />
              {habitualidadeDadosTreino.qtd_disparos}
              <br />
              {habitualidadeDadosTreino.num_serie_equipamento}
            </Col>
          </Row>
        </Card>

        <Card style={{ width: "80%", marginTop: "20px" }}>
          <Row
            gutter={16}
            style={{
              height: "350px",
              alignItems: "center",
            }}
          >
            <Col span={24}>
              <GraficoTreino></GraficoTreino>
            </Col>
          </Row>
        </Card>

        <Card
          style={{
            width: "80%",
            margin: "0 auto",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "#fff",
                  border: "solid 1px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "24px",
                  fontWeight: "bold",
                  margin: "0 auto",
                }}
              >
                {qtdHabitualidadeCompeticao}
              </div>
            </Col>
            <Col xs={24} sm={16}>
              <div style={{ textAlign: "center" }}>
                <Text strong>Competições nos últimos 12 meses.</Text>
              </div>
              <div style={{ textAlign: "center" }}>
                Considerando apenas uma por dia conforme regulamentação atual.
              </div>
            </Col>
          </Row>
        </Card>

        <Card style={{ width: "80%", marginTop: "20px" }}>
          <Row
            gutter={16}
            style={{
              height: "150px",
              alignItems: "center",
            }}
          >
            <Col span={24}>
              <Text strong>Última Competição</Text>
            </Col>
            <Col span={12}>
              Data:
              <br />
              Clube:
              <br />
              Calibre:
              <br />
              Disparos:
              <br />
              N.Série:
            </Col>
            <Col span={12}>
              {habitualidadeDadosComp
                ? format(
                    new Date(habitualidadeDadosComp.data),
                    "dd/MM/yyyy HH:mm:ss"
                  )
                : ""}
              <br />
              {habitualidadeDadosComp.clube}
              <br />
              {habitualidadeDadosComp.calibre}
              <br />
              {habitualidadeDadosComp.qtd_disparos}
              <br />
              {habitualidadeDadosComp.num_serie_equipamento}
            </Col>
          </Row>
        </Card>

        <Card style={{ width: "80%", marginTop: "20px" }}>
          <Row
            gutter={16}
            style={{
              height: "350px",
              alignItems: "center",
            }}
          >
            <Col span={24}>
              <GraficoComp></GraficoComp>
            </Col>
          </Row>
        </Card>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #484848;
  width: 100%;
  overflow: hidden;
  background-image: url(${camuflado});
`;

const TituloHabitualidade = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: #000;
  margin-top: 20;
`;

const ContainerInterno = styled.div`
  display: flex;
  width: 80%;
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  height: 200;
  margin-top: 20;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 16;
  overflow: hidden;
`;
const GridItem = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
`;

const ViewCirculo = styled.div`
  width: 80%;
  height: 200px;
  background-color: white;
  border-radius: 50;
  border-width: 2px;
  border-color: black;
  margin: 20;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

export default Home;
