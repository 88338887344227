import React, { useState, useEffect, useCallback, useRef } from "react";
import camuflado from "../../../camuflado.jpg";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerCustomStyles.css";

import {
  Col,
  Card,
  Row,
  Typography,
  Input,
  Form,
  Button,
  Skeleton,
  Select,
} from "antd";

import { useHistory, useParams } from "react-router-dom";
import ptBR from "date-fns/locale/pt-BR"; // Importe o locale desejado
import CameraModal from "./CameraModal";
import axiosInstance from "../../../axiosInterceptor";

const { Text } = Typography;
const { TextArea } = Input;

const EditarHabitualidade = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const chave = window.localStorage.getItem("chaveCofre");
  const { idHabitualidadeEditar } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [b64img, setB64img] = useState(null);
  const [b64imgNova, setB64imgNova] = useState(null);
  const [mostraBtn, setMostraBtn] = useState(false);
  const [nome, setNome] = useState("");
  const [obs, setObs] = useState("");
  const [cpf, setCpf] = useState("");
  const [cr, setCr] = useState("");
  const [numSerieEquipamento, setNumSerieEquipamento] = useState("");
  const [calibre, setCalibre] = useState("");
  const [qtdDisparos, setQtdDisparos] = useState("");
  const [imagem, setImagem] = useState("");
  const [tipoDaHabitualidade, setTipoDaHabitualidade] = useState("TREINAMENTO");
  const [dataDaHabitualidade, setDataDaHabitualidade] = useState("");
  const [dataDaHabitualidadeDatePicker, setDataDaHabitualidadeDatePicker] =
    useState(new Date());

  const [clubes, setClubes] = useState([]);
  const [selectedClube, setSelectedClube] = useState("");
  const scrollViewRef = useRef(null);

  const history = useHistory();

  const handleClubeChange = (value) => {
    setSelectedClube(value);
  };

  const handleHabitualidadeChange = (value) => {
    setTipoDaHabitualidade(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      // if (!clubes) {
      try {
        const response = await axiosInstance.get(
          `https://app-photo-api-java.herokuapp.com/v1/clube/usuario/${idUser}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (Array.isArray(response.data)) {
          setClubes(response.data);
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
      //  }
    };

    fetchData();
  }, [accessToken, idUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };
        const chaveCorpo = {
          chave: chave,
        };
        // Alterado para post para envio da chave
        const response = await axiosInstance.post(
          `https://app-photo-api-java.herokuapp.com/v1/habitualidade/${idHabitualidadeEditar}/buscarnova`,
          chaveCorpo,
          options
        );

        setSelectedClube(response.data.clube.id);
        const timestamp = Date.parse(response.data.data);

        setDataDaHabitualidadeDatePicker(new Date(timestamp));

        setNome(response.data.nome);
        setObs(response.data.obs);

        setCpf(response.data.cpf);
        setCr(response.data.cr);
        setNumSerieEquipamento(response.data.num_serie_equipamento);
        setCalibre(response.data.calibre);
        setQtdDisparos("" + response.data.qtd_disparos);
        setB64img(response.data.imagem);
        setMostraBtn(true);
        setTipoDaHabitualidade(response.data.tipoDaHabitualidade);
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [idHabitualidadeEditar, accessToken, chave]);
  const limpaImg = () => {
    setMostraBtn(false);
    setSelectedImage(null);
    setB64img(null);
    setB64imgNova();
  };
  function tornarAlfanumerico(str) {
    return str.replace(/[^a-zA-Z0-9]/g, "");
  }

  function tornarAlfanumericoComPonto(str) {
    return str.replace(/[^a-zA-Z0-9.]/g, "");
  }

  const onFinish = (data) => {
    data.idUsuario = idUser;

    const data2 = {
      usuario: {
        id: idUser,
      },
      clube: {
        id: selectedClube,
      },
      nome: data.nome,
      data: dataDaHabitualidadeDatePicker,
      cpf: tornarAlfanumerico(data.cpf),
      cr: data.cr,
      obs: data.observacoes,
      num_serie_equipamento: data.numSerieEquipamento,
      calibre: data.calibre,
      qtd_disparos: data.qtdDisparos,
      imagem: b64img,
      tipoDaHabitualidade: tipoDaHabitualidade,
      chave: chave,
    };
    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };

    axiosInstance
      .put(
        `https://app-photo-api-java.herokuapp.com/v1/habitualidade/${idHabitualidadeEditar}/nova`,
        data2,
        options
      )
      .then((response) => {
        setLoading(false);
        window.alert("Habitualidade salva com sucesso!");

        setTimeout(() => {
          history.push("/2");
        }, 300);
      })
      .catch((error) => {
        console.error("Erro na requisição:", error);
        setLoading(false);
        window.alert("Falha na edição");
        setTimeout(() => {}, 300);
      })
      .finally(() => {});
  };

  const inputFileRef = useRef(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Content = e.target.result.split(",")[1]; // Remove o cabeçalho
        setB64img(base64Content);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const { Option } = Select;
  return (
    <Container>
      <Row
        gutter={16}
        style={{
          justifyContent: "center",
          width: "100%",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <Col xs={24} lg={16}>
          <Card style={{ width: "100%" }}>
            <Row
              gutter={16}
              style={{
                justifyContent: "center",
              }}
            >
              {loading ? (
                <Skeleton active />
              ) : (
                <Form onFinish={onFinish}>
                  <Form.Item
                    label="Nome"
                    initialValue={nome}
                    name="nome"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o nome!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Data" name="data">
                    <DatePicker
                      selected={dataDaHabitualidadeDatePicker}
                      onChange={(date) =>
                        setDataDaHabitualidadeDatePicker(date)
                      }
                      style={{ width: "50%" }}
                      dateFormat="dd/MM/yyyy HH:mm"
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      timeCaption="Hora"
                      locale={ptBR}
                    />
                  </Form.Item>
                  <Form.Item label="Clube:">
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Selecione um clube..."
                      value={selectedClube}
                      onChange={handleClubeChange}
                    >
                      {clubes.map((clube) => (
                        <Option key={clube.id} value={clube.id}>
                          {clube.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Tipo da Habitualidade:">
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Selecione o tipo..."
                      value={tipoDaHabitualidade}
                      onChange={handleHabitualidadeChange}
                    >
                      <Option key={"TREINAMENTO"} value={"TREINAMENTO"}>
                        Treinamento
                      </Option>
                      <Option key={"COMPETIÇÃO"} value={"COMPETIÇÃO"}>
                        Competição
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="CPF"
                    name="cpf"
                    initialValue={cpf}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o cpf!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="CR"
                    name="cr"
                    initialValue={cr !== "N/A" ? cr : ""}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o cr!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Número de Série do Equipamento"
                    name="numSerieEquipamento"
                    initialValue={numSerieEquipamento}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Calibre"
                    name="calibre"
                    initialValue={calibre}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o calibre!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Quantidade de disparos"
                    name="qtdDisparos"
                    initialValue={qtdDisparos}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira a quantidade de disparos!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Obs" name="observacoes">
                    <TextArea
                      rows={4}
                      defaultValue={obs}
                      placeholder="Digite suas observações aqui..."
                    />
                  </Form.Item>

                  {chave && mostraBtn ? (
                    <Form.Item>
                      <Button
                        type="primary"
                        style={{ backgroundColor: "black", width: "100%" }}
                        onClick={limpaImg}
                      >
                        LIMPAR IMAGEM
                      </Button>
                    </Form.Item>
                  ) : (
                    ""
                  )}

                  {b64img ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {chave ? (
                        <Image
                          src={`data:image/png;base64,${b64img}`}
                          style={{ width: 200, height: 200 }}
                        />
                      ) : (
                        <Image style={{ width: 200, height: 200 }} />
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {chave ? (
                    <Button
                      type="primary"
                      style={{ backgroundColor: "black", width: "100%" }}
                      onClick={() => inputFileRef.current.click()}
                    >
                      ESCOLHER UMA IMAGEM DA GALERIA
                    </Button>
                  ) : (
                    ""
                  )}
                  {chave ? (
                    <input
                      ref={inputFileRef}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                  ) : (
                    ""
                  )}

                  {selectedImagePreview && (
                    <div>
                      <img src={selectedImagePreview} alt="Selected" />
                    </div>
                  )}

                  {chave ? (
                    <CameraModal setImagem={setB64img}></CameraModal>
                  ) : (
                    ""
                  )}
                  <br />
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    Salvar
                  </Button>

                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      history.push("/2");
                    }}
                  >
                    Voltar
                  </Button>
                </Form>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #484848;
  width: 100%;
  height: "1300px";
  margin: 0;
  padding: 0;
  overflow: auto;
  background-image: url(${camuflado});
`;
const ImageDiv = styled.div`
  width: 150px;
  height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;
export default EditarHabitualidade;
