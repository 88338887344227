const initialState = {
  accessToken: "",
  isLogged: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.payload,
        isLogged: true,
      };
    case "CLEAR_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: null,
        isLogged: false,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isLogged: true,
      };
    case "LOGOUT":
      return {
        ...state,
        isLogged: false,
      };

    case "SET_ID_USER":
      return {
        ...state,
        idUser: action.payload,
      };
    case "CLEAR_ID_USER":
      return {
        ...state,
        idUser: null,
      };
    default:
      return state;
  }
};

export default reducer;
